import { useEffect } from "react";
import { LatLng } from "../../types/map";

type Props = {
  onInitCenterGeo: (geoLocation: LatLng) => void;
  onInitZoomLevel: (zoomLevel: number) => void;
};

/**
 * Component design architecture change:
 *
 * [ ] Use `setTimeout` instead of `useEffect` every seconds
 * [ ] Change `onInitCenterGeo` to `onChangeCenterGeo`
 * [ ] Increase zoom level closer to group?
 */
const useNavLocation = ({ onInitCenterGeo, onInitZoomLevel }: Props) => {
  useEffect(() => {
    navigator.geolocation ||
      console.warn(
        `[Component] MapPage - Navigation Geo Location feature unavailable`
      );
    navigator.geolocation &&
      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          onInitCenterGeo({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
          onInitZoomLevel(6);
        },
        () => console.warn(`[Component] MapPage - Geo Location access refused`)
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useNavLocation;
