import { MapLocation, MapItinerary, GoogleDirectionResult } from "../types/map";
import { flow, get, pick } from "lodash/fp";

export const neutralMapLocation = (): MapLocation => ({
  formattedAddress: "",
  latitude: null,
  longitude: null,
});

export const hasLatLong = ({ latitude, longitude }: MapLocation) =>
  Boolean(latitude && longitude);

export const toGoogleLatLong = (
  latitude: number | null,
  longitude: number | null
) => ({
  lat: latitude ?? 0,
  lng: longitude ?? 0,
});

const selectDistance = get("[0].distance");
const selectDuration = get("[0].duration");

export const mapDirectionResultParser = (
  directionResult: GoogleDirectionResult | null
) => {
  if (!directionResult) {
    console.error("[Library] mapDirectionResultParser - result empty");
    return {
      distanceMeters: 0,
      distanceLabel: "",
      durationSeconds: 0,
      googleDirection: null,
      bounds: null,
    };
  }

  return flow(
    get("routes[0]"),
    pick(["legs", "bounds"]),
    ({ legs, bounds }): Omit<MapItinerary, "locationFrom" | "locationTo"> => ({
      distanceMeters: selectDistance(legs)?.value ?? 0,
      distanceLabel: selectDistance(legs)?.text ?? "",
      durationSeconds: selectDuration(legs)?.value ?? 0,
      googleDirection: directionResult,
      bounds,
    })
  )(directionResult);
};
