import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/material";

import { GoogleMap } from "types/map";
import useMapRenderer from "hooks/maps/useMapRenderer";
import MapMarkerCurrentPosition from "components/map/MapMarkerCurrentPosition";

const APP_BOTTOM_BAR_HEIGHT = 56;
const MAP_CONTAINER_HEIGHT_BOUNDARIES = APP_BOTTOM_BAR_HEIGHT;

const MapBoundaries = styled("div")`
  height: calc(100vh - ${MAP_CONTAINER_HEIGHT_BOUNDARIES}px);
  width: 100%;
`;

/*
 * SDK MapOptions documentation:
 *
 * https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions
 */
interface Props extends google.maps.MapOptions {
  onClick?: (event: google.maps.MapMouseEvent) => void;
  onIdle?: (map: GoogleMap) => void;
  children: React.ReactElement[];
}

const MapContainer = ({
  children,
  onClick,
  onIdle,
  ...googleMapOptions
}: Props) => {
  const wrapperReference = useRef<HTMLDivElement | null>(null);
  const [map, setMap] = useState<GoogleMap>();

  useMapRenderer({
    onMapInitialized: setMap,
    mapWrapperReference: wrapperReference,
    googleMapOptions,
  });

  // Register det events "click" and "idle"
  // Full list: https://developers.google.com/maps/documentation/javascript/events
  useEffect(() => {
    map && google.maps.event.clearListeners(map, "click");
    map && google.maps.event.clearListeners(map, "idle");
    map && onClick && map.addListener("click", onClick);
    map && onIdle && map.addListener("idle", () => onIdle(map));
  }, [map, onClick, onIdle]);

  const renderUserMarker = () => {
    return `
      <div style="display: flex; align-items: center; justify-content: center; font-size: 40px;">
  <img src="${process.env.PUBLIC_URL}/user.svg" width="40" height="40" alt="Audi Logo" />
</div>
      `;
  };

  return (
    <>
      <MapBoundaries ref={wrapperReference} />
      <MapMarkerCurrentPosition map={map} content={renderUserMarker()} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          return React.cloneElement(child, { map } as any);
        }
      })}
    </>
  );
};

export default MapContainer;
