import { useEffect, useRef } from "react";
import useLiveLocation from "hooks/maps/useLiveLocation";
import useInitialPosition from "hooks/maps/useInitialPosition";

type AdvancedMarkerElementProps = {
  map: google.maps.Map | undefined; // Map instance passed from MapContainer
  content: string; // Custom HTML content for the marker
};

const MapMarkerCurrentPosition = ({
  map,
  content,
}: AdvancedMarkerElementProps) => {
  const { initialPosition } = useInitialPosition();
  const { liveLocation } = useLiveLocation();

  const markerRef = useRef<google.maps.marker.AdvancedMarkerElement | null>(
    null
  );
  const contentRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!map || !content || (!initialPosition && !liveLocation)) return;

    const currentPosition = liveLocation || initialPosition;

    // Create the HTML content for the AdvancedMarkerElement
    if (!contentRef.current) {
      contentRef.current = document.createElement("div");
      contentRef.current.innerHTML = content;
    }

    // Create marker only once
    if (!markerRef.current) {
      markerRef.current = new google.maps.marker.AdvancedMarkerElement({
        map,
        position: currentPosition,
        content: contentRef.current,
        gmpClickable: false, // Correct property for disabling interactivity
      });
    } else {
      // Update only the marker's position
      markerRef.current.position = currentPosition;
    }

    return () => {
      // Cleanup: Remove content and marker from the map
      if (markerRef.current) {
        markerRef.current.map = null;
        markerRef.current = null;
      }
    };
  }, [map, content, initialPosition, liveLocation]);

  return null;
};

export default MapMarkerCurrentPosition;
