import { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { selectReferenceCar } from "redux/slices/shed/shedSlice";
import { useAppSelector } from "redux/hooks";
import { CarState } from "types/cars";
import useDisplayMessage from "hooks/useDisplayMessage";

const CarNoEmissionPopup = () => {
  const referenceCar = useAppSelector(selectReferenceCar);
  const [prevCar, setPrevCar] = useState<CarState | null>(null);
  const [open, setOpen] = useState(false);

  const { displayMessage } = useDisplayMessage();

  useEffect(() => {
    if (prevCar !== referenceCar && referenceCar?.emissionCO2 === 0) {
      setOpen(true);
    }
  }, [referenceCar, prevCar]);

  const handleClose = () => {
    setPrevCar(referenceCar);
    setOpen(false);
  };

  const handleReport = () => {
    setPrevCar(referenceCar);
    setOpen(false);
    displayMessage(
      "Thank you, your car emission data will be available in the next 24 hours",
      "success"
    );
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Important information"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Your car does not yet have CO2 data. Do you want to report it?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleReport} color="primary" autoFocus>
          Report that I am missing CO2 data
        </Button>
        <Button onClick={handleClose} color="primary" autoFocus>
          Lukk
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CarNoEmissionPopup;
