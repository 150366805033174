import { useState, useEffect } from "react";
import { LatLng } from "../../types/map";

type ReturnType = {
  liveLocation: LatLng | null;
};

const useLiveLocation = (): ReturnType => {
  const [liveLocation, setLiveLocation] = useState<LatLng | null>(null);

  useEffect(() => {
    if (!navigator.geolocation) {
      console.warn("[useLiveLocation] Geolocation is not supported.");
      return;
    }

    //When watchPosition is called, it actively listens for changes in the user's geolocation,
    // such as when they move, the device recalibrates, or the GPS acquires a new position.

    const watchId = navigator.geolocation.watchPosition(
      (position: GeolocationPosition) => {
        setLiveLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      (error) => {
        console.warn("[useLiveLocation] Error watching positin: ", error);
      }
    );

    // Cleanup the watch position when the component unmounts
    return () => {
      navigator.geolocation.clearWatch(watchId);
    };
  }, []);

  return { liveLocation };
};

export default useLiveLocation;
