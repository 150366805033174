import { createContext, ReactNode, useEffect, useMemo, useState } from "react";
import { Loader } from "@googlemaps/js-api-loader";

interface MapContextInterface {
  initialized: boolean;
  mapsLibraries: typeof google.maps | null;
}

const mapContextDefaultValue: MapContextInterface = {
  initialized: false,
  mapsLibraries: null,
};

export const MapContext = createContext<MapContextInterface>(
  mapContextDefaultValue
);

const MapProvider = ({ children }: { children: ReactNode }) => {
  const [initialized, setInitialized] = useState(false);
  const [mapsLibraries, setMapsLibraries] = useState<typeof google.maps | null>(
    null
  );

  useEffect(() => {
    const googleApiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
    if (!googleApiKey) {
      console.error("[Provider] MapProvider - loader no API_KEY env set");
      return;
    }

    const loadLibraries = async () => {
      const googleLoader = new Loader({
        apiKey: googleApiKey,
        version: "weekly",
        libraries: ["maps", "places", "routes", "marker"],
      });
      const { maps } = await googleLoader.load();
      setMapsLibraries(maps);
      setInitialized(true);
    };

    loadLibraries();
  }, []);

  const contextValue = useMemo(
    () => ({
      mapsLibraries,
      initialized,
    }),
    [initialized, mapsLibraries]
  );

  return (
    <MapContext.Provider value={contextValue}>{children}</MapContext.Provider>
  );
};

export default MapProvider;
