import { useEffect, useState } from "react";
import { LatLng } from "../../types/map";

type ReturnType = {
  initialPosition: LatLng | null;
};

const useInitialPosition = (): ReturnType => {
  const [initialPosition, setInitialPosition] = useState<LatLng | null>(null);

  useEffect(() => {
    if (!navigator.geolocation) {
      console.warn(
        "[useInitialPosition] Navigation Geo Location feature unavailable"
      );
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position: GeolocationPosition) =>
        setInitialPosition({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }),
      () => console.warn("[useInitialPosition] Geo location access refused")
    );
  }, []);

  return { initialPosition };
};

export default useInitialPosition;
